<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="780"
         class-name="vertical-center-modal"
         :styles="{'z-index': '2000'}">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>计划名称：</p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 placeholder="计划名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>责任人：</p>
          <MyTreeSelect :value="principal"
                        placeholder="责任人"
                        :data="principalTreeData"
                        search
                        style="width:100%"
                        @onSelectChange="onChangePrincipal">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>应急预案：</p>
          <Select v-model="contingencyPlan"
                  @on-change="onChangePlan">
            <Option v-for="item in contingencyPlanArr"
                    :value="item.id"
                    :key="item.id">{{ item.resplanname }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half"></li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>开始时间：</p>
          <DatePicker type="datetime"
                      :value="startTime"
                      style="width:100%;"
                      :editable="false"
                      @on-change="onChangeStartTime"
                      placeholder="开始时间"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>结束时间：</p>
          <DatePicker type="datetime"
                      :value="endTime"
                      style="width:100%;"
                      :editable="false"
                      @on-change="onChangeEndTime"
                      placeholder="结束时间"></DatePicker>
        </li>
        <li class="form-ul-li">
          <p class="li-title">多媒体文件：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="planPicture">
              <li>
                <el-image :src="planPicture"
                          fit="cover"
                          :preview-src-list="[planPicture]"
                          class="w-full h-full"
                          v-show="planPicture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="planPicture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!planPicture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">作业现场描述：</p>
          <Input v-model="desc"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="作业现场描述" />
        </li>
      </ul>

      <div class="section-title">
        <span>作业位置</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业位置：</p>
          <RadioGroup v-model="site">
            <Radio :label="0">
              选点
            </Radio>
            <Radio :label="1">
              选网格
            </Radio>
            <Radio :label="2">
              画网格
            </Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li"
            v-show="site == 0">
          <p class="li-title"></p>
          <Input v-model="address"
                 readonly
                 placeholder="">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li"
            v-show="site == 1">
          <p class="li-title"></p>
          <MyTreeSelect :value="gridName"
                        placeholder="网格名称"
                        clearable
                        style="width:100%"
                        :data="gridNameTreeArr"
                        search
                        @onSelectChange="onChangeGrid">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li"
            v-show="site == 2">
          <p class="li-title"></p>
          <Input v-model="gridName"
                 readonly
                 placeholder="">
          <Icon custom="i-icon icon-quyu1"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickDrawGrid" />
          </Input>
        </li>
      </ul>

      <div class="section-title">
        <span>作业车辆和人员</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>请选择车辆：</p>
          <div class="list">
            <div class="list-item"
                 v-for="(item,index) in carList"
                 :key="index">
              <span class="line1">{{item.name}}</span>
              <Icon custom="i-icon icon-guanbi"
                    size="16"
                    color="#6a6b75"
                    class="cursor-pointer"
                    @click.stop="carList.splice(index,1)" />
            </div>
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>请选择人员：</p>
          <div class="list">
            <div class="list-item"
                 v-for="(item,index) in personList"
                 :key="index">
              <span class="line1">{{item.name}}</span>
              <Icon custom="i-icon icon-guanbi"
                    size="16"
                    color="#6a6b75"
                    class="cursor-pointer"
                    @click.stop="personList.splice(index,1)" />
            </div>
          </div>
        </li>
        <li class="form-ul-li half">
          <div class="selectlist">
            <div class="title">
              <MyTreeSelect :value="carDeptName"
                            placeholder="请选择部门"
                            :data="carDeptArr"
                            search
                            :disabled="carDisable"
                            style="width:100%"
                            @onSelectChange="onChangeCarDept">
              </MyTreeSelect>
            </div>
            <div class="cont">
              <div class="cont-box">
                <div class="cont-item"
                     v-for="(item,index) in carSelectList"
                     :key="index"
                     @click.stop="onClickSelectCar(item)"
                     :class="{current : getCarSelect(item) >= 0,green:item.shiftStatus==0,orange:item.shiftStatus==1}">
                  <p>{{item.name.substring(0,1)}}</p>
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
            <ul class="footer">
              <li>
                <span class="green"></span>
                空闲
              </li>
              <li>
                <span class="orange"></span>
                有排班
              </li>
            </ul>
          </div>
        </li>
        <li class="form-ul-li half">
          <div class="selectlist">
            <div class="title">
              <MyTreeSelect :value="personDeptName"
                            placeholder="请选择部门"
                            :data="personDeptArr"
                            search
                            :disabled="personDisable"
                            style="width:100%"
                            @onSelectChange="onChangePersonDept">
              </MyTreeSelect>
            </div>
            <div class="cont">
              <div class="cont-box">
                <div class="cont-item"
                     v-for="(item,index) in personSelectList"
                     :key="index"
                     @click.stop="onClickSelectPerson(item)"
                     :class="{current : getPersonSelect(item) >= 0,green:item.shiftStatus==0,orange:item.shiftStatus==1}">
                  <p>{{item.name.substring(0,1)}}</p>
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
            <ul class="footer">
              <li>
                <span class="green"></span>
                空闲
              </li>
              <li>
                <span class="orange"></span>
                有排班
              </li>
            </ul>
          </div>
        </li>
      </ul>

    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
    <!-- 选择地址 -->
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />

    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="width"
               :sourceType="sourceType"
               @onClickConfirm='onClickDrawConfirm'></GridModal>
  </Modal>
</template>

<script>
import UploadFile from '@/components/common/UploadFile';
import PositionModal from '@/components/product/main/template/PositionModal'
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
import { mapActions, mapGetters } from 'vuex';
import GridModal from '@/components/product/admin/modal/work/GridModal'
import { formatDate, formatTime } from '@/utils/dateRangUtil'
export default {
  name: 'EmergencyDispatchPlanModal',
  components: {
    UploadFile,
    PositionModal,
    MyTreeSelect,
    GridModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '应急计划'
    },
    visible: Boolean,
    dataId: String,
    width: Number,
    sourceType: String,
    resPlanId: String
  },
  data () {
    return {
      loading: false,
      name: '',   // 计划名称
      principal: '',  // 责任人
      principalId: '',
      contingencyPlan: '',  // 应急预案
      startTime: '2021-09-18 09:30:00',
      endTime: '',
      planPicture: '',  // 多媒体图片
      desc: '',
      site: 0,
      gridName: '',
      gridId: '',
      carList: [],
      carSelectList: [],
      personList: [],
      personSelectList: [],
      // 选择车
      planCarArr: [],
      planPersonArr: [],
      carDeptName: '',
      carDeptId: '',
      carDeptArr: [],
      carTypeArr: [],  // 预案的车类型列表
      carDisable: true,
      // 选择人
      personDeptName: '',
      personDeptId: '',
      personDeptArr: [],
      personTypeArr: [],  // 预案的人类型列表
      personDisable: true,
      // contingencyPlanArr: [{
      //   id: "2ef862fb22f430be83ad7f82dc5a894e",
      //   name: "预案测试03",
      //   tlCarTypes: [
      //     {
      //       createby: "d073e94ffa044e3eb436d3ed18e8ea01",
      //       createtime: "2021-05-17 10:17:14",
      //       deptid: "7e730dcd561f4c3f9e6e1c434ec74481",
      //       icon: "",
      //       id: "519996bcc7d5997e3fd80672a74fce7c",
      //       monitorcleardish: "",
      //       monitornozzle: "",
      //       operationtypeid: "转运车",
      //       ransporttype: "",
      //       remark: "",
      //       status: "1",
      //       typename: "转运车",
      //       updateby: null,
      //       updatetime: null,
      //     }
      //   ],
      //   tlWorkTypes: [
      //     {
      //       createby: "d073e94ffa044e3eb436d3ed18e8ea01",
      //       createtime: "2021-05-15 10:50:46",
      //       deptid: "7e730dcd561f4c3f9e6e1c434ec74481",
      //       icon: "people",
      //       id: "ed9e66be6b6ed114fa338d20af78ecfc",
      //       isdriver: "0",
      //       remark: null,
      //       status: "1",
      //       updateby: null,
      //       updatetime: null,
      //       worktype: "清运工",
      //     }
      //   ]
      // }],
      // principalTreeData: [],
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      address: '',
      // 画网格
      gridVisible: false
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible () {
      this.startTime = formatDate(new Date()) + ' ' + formatTime(new Date())
    },
    contingencyPlan (newVal) {
      if (newVal) {
        let data = this.contingencyPlanArr.find(item => {
          return item.id == newVal
        })
        this.carTypeArr = data.tlCarTypes.map(item => {
          return item.id
        })
        this.personTypeArr = data.tlWorkTypes.map(item => {
          return item.id
        })
        this.getCarList()
        this.getPersonList()
      }
      if (newVal && this.endTime && this.startTime) {
        this.carDisable = false
        this.personDisable = false
      }
    },
    startTime (newVal) {
      if (new Date(this.startTime) > new Date(this.endTime)) {
        this.$Message.info('请选择正确时间');
      }
      if (this.startTime && this.endTime && new Date(this.startTime) <= new Date(this.endTime)) {
        this.getPlanCarOrPersonList(this.startTime, this.endTime)
      } else {
        this.carList = []
        this.personList = []
        this.carSelectList = []
        this.personSelectList = []
        this.carDeptArr = []
        this.carDeptName = ''
        this.personDeptArr = []
        this.personDeptName = ''
        this.carDisable = true
        this.personDisable = true
      }
      if (newVal && this.endTime && this.contingencyPlan) {
        this.carDisable = false
        this.personDisable = false
      }
    },
    endTime (newVal) {
      if (new Date(this.startTime) > new Date(this.endTime)) {
        this.$Message.info('请选择正确时间');
      }
      if (this.startTime && this.endTime && new Date(this.startTime) <= new Date(this.endTime)) {
        this.getPlanCarOrPersonList(this.startTime, this.endTime)
      } else {
        this.carList = []
        this.personList = []
        this.carSelectList = []
        this.personSelectList = []
        this.carDeptArr = []
        this.carDeptName = ''
        this.personDeptArr = []
        this.personDeptName = ''
        this.carDisable = true
        this.personDisable = true
      }
      if (newVal && this.startTime && this.contingencyPlan) {
        this.carDisable = false
        this.personDisable = false
      }
    },
    resPlanId (newVal) {
      newVal && (this.contingencyPlan = newVal)
    }
  },
  computed: {
    ...mapGetters({
      'principalTreeData': 'getUserTreeList',
      'contingencyPlanArr': 'getIntendAllList',
      'gridNameTreeArr': 'getGridTreeList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateUserTreeList',
      'updateIntendAllList',
      'updateGridTreeList'
    ]),
    init () {
      this.updateUserTreeList()
      this.updateIntendAllList()
      this.updateGridTreeList()
    },
    getDetail () {
      this.$store.dispatch('getEmergencyPlanningDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        // 渲染车列表
        let carArr = []
        res.carList.forEach(item => {
          item.calist.forEach(items => {
            carArr.push(items)
          })
        })
        this.carList = carArr.map(item => {
          return {
            id: item.carid,
            name: item.carname
          }
        })

        // 渲染人列表
        let personArr = []
        res.personList.forEach(item => {
          item.emplist.forEach(items => {
            personArr.push(items)
          })
        })
        this.personList = personArr.map(item => {
          return {
            id: item.empid,
            name: item.empname
          }
        })
        if (res.gridId) {
          this.site = 1
        } else {
          this.site = 0
        }
      })
    },
    // 选择预案回调
    onChangePlan (value) {
      this.carList = []
      this.personList = []
      // if (value) {
      //   let data = this.contingencyPlanArr.find(item => {
      //     return item.id == value
      //   })
      //   this.carTypeArr = data.tlCarTypes.map(item => {
      //     return item.id
      //   })
      //   this.personTypeArr = data.tlWorkTypes.map(item => {
      //     return item.id
      //   })
      //   this.getCarList()
      // }
    },
    // 选择责任人
    onChangePrincipal (section) {
      if (section.type == 'user') {
        this.principal = section.name
        this.principalId = section.id
      }
    },
    // 开始时间
    onChangeStartTime (data) {
      this.startTime = data
    },
    // 结束时间
    onChangeEndTime (data) {
      this.endTime = data
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.planPicture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.planPicture = ''
    },
    // 获取车辆 人员列表
    getPlanCarOrPersonList (startTime = this.startTime, endTime = this.endTime) {
      let params = {
        startDate: startTime,
        endDate: endTime,
      }
      this.$http.getPlanCarList(params).then((res) => {
        this.planCarArr = res.result
        let filterCarArr = JSON.parse(JSON.stringify(res.result))
        this.carDeptArr = this.filter(filterCarArr, 'car')
        this.carDeptName = this.carDeptArr[0].name
        this.carDeptId = this.carDeptArr[0].id
        this.getCarList()
      })
      this.$http.getPlanPersonList(params).then((res) => {
        this.planPersonArr = res.result
        let filterPersonArr = JSON.parse(JSON.stringify(res.result))
        this.personDeptArr = this.filter(filterPersonArr, 'user')
        this.personDeptName = this.personDeptArr[0].name
        this.personDeptId = this.personDeptArr[0].id
        this.getPersonList()
      })
    },
    // 选择车的部门
    onChangeCarDept (section) {
      this.carDeptName = section.name
      this.carDeptId = section.id
      this.getCarList()
    },
    // 筛选符合预案中车的类型与部门的车辆列表
    getCarList () {
      // 部门筛选
      let deptObj = {}
      const getDeptObj = (list, id) => {
        list.forEach(item => {
          if (item.id == this.carDeptId) {
            deptObj = item
          }
          if (item.children) {
            getDeptObj(item.children, this.carDeptId)
          }
        });
      }
      // 传入返回的车辆部门树与选中的部门id，提取该部门对象
      getDeptObj(this.planCarArr, this.carDeptId)

      // 部门下的所有车
      let deptCarArr = []
      const getDeptCarList = (list) => {
        list.forEach(item => {
          if (item.type == 'car') {
            deptCarArr.push(item)
          }
          if (item.children) {
            getDeptCarList(item.children)
          }
        })
      }
      // 传入部门对象下的children，筛选出该部门及下级部门中的所有车辆
      if (deptObj.children && deptObj.children.length > 0) {
        getDeptCarList(deptObj.children)
      }

      let carTypeArr = []
      deptCarArr.forEach(item => {
        if (this.carTypeArr.includes(item.carTypeId)) {
          carTypeArr.push(item)
        }
      })
      this.carSelectList = carTypeArr
    },
    // 点击选择车
    onClickSelectCar (item) {
      let index = this.carList.findIndex(items => {
        return items.name == item.name
      })
      if (index < 0) {
        this.carList.push(item)
      } else {
        this.carList.splice(index, 1)
      }
    },
    // 判断是否已经选择车
    getCarSelect (item) {
      let index = this.carList.findIndex(items => {
        return items.name == item.name
      })
      return index
    },
    // 选择人的部门
    onChangePersonDept (section) {
      this.personDeptName = section.name
      this.personDeptId = section.id
      this.getPersonList()
    },
    getPersonList () {
      // 部门筛选
      let deptObj = {}
      const getDeptObj = (list, id) => {
        list.forEach(item => {
          if (item.id == this.personDeptId) {
            deptObj = item
          }
          if (item.children) {
            getDeptObj(item.children, this.personDeptId)
          }
        });
      }
      // 传入返回的人员部门树与选中的部门id，提取该部门对象
      getDeptObj(this.planPersonArr, this.personDeptId)

      // 部门下的所有人
      let deptPersonArr = []
      const getDeptPersonList = (list) => {
        list.forEach(item => {
          if (item.type == 'user') {
            deptPersonArr.push(item)
          }
          if (item.children) {
            getDeptPersonList(item.children)
          }
        })
      }
      // 传入部门对象下的children，筛选出该部门及下级部门中的所有人
      if (deptObj.children && deptObj.children.length > 0) {
        getDeptPersonList(deptObj.children)
      }

      let personTypeArr = []
      deptPersonArr.forEach(item => {
        if (this.personTypeArr.includes(item.employeeTypeId)) {
          personTypeArr.push(item)
        }
      })
      this.personSelectList = personTypeArr
    },
    // 点击选择人
    onClickSelectPerson (item) {
      let index = this.personList.findIndex(items => {
        return items.name == item.name
      })
      if (index < 0) {
        this.personList.push(item)
      } else {
        this.personList.splice(index, 1)
      }
    },
    // 判断是否已经选择人
    getPersonSelect (item) {
      let index = this.personList.findIndex(items => {
        return items.name == item.name
      })
      return index
    },
    /**
     * 树结构数据条件过滤
     * js 指定删除数组(树结构数据)
     */
    filter (data, type) {
      var newData = data.filter(x => x.type !== type)
      newData.forEach(x => x.children && (x.children = this.filter(x.children, type)))
      return newData
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeSite (e) {
      this.address = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 选网格
    onChangeGrid (section) {
      if (section.type != 'depart') {
        this.gridId = section.id
        this.gridName = section.name
      }
    },
    // 画网格
    onClickDrawGrid () {
      this.gridVisible = true
    },
    // 画网格回调
    onClickDrawConfirm (data) {
      this.gridName = data.name
      this.gridId = data.gridId
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入计划名称');
      if (!this.principal) return this.$Message.info('请选择负责人');
      if (!this.contingencyPlan) return this.$Message.info('请选择应急预案');
      if (!this.startTime) return this.$Message.info('请选择开始时间');
      if (!this.endTime) return this.$Message.info('请选择结束时间');
      // 作业位置未校验
      if (this.site == 0) {
        if (!this.address) return this.$Message.info('请选择地点');
      }
      if (this.site == 1) {
        if (!this.gridId) return this.$Message.info('请选择网格');
      }
      if (this.site == 2) {
        if (!this.gridId) return this.$Message.info('请绘制网格');
      }

      if (this.carList.length == 0) return this.$Message.info('请选择车辆');
      if (this.personList.length == 0) return this.$Message.info('请选择人员');
      let carList = this.carList.map(item => {
        return {
          carid: item.id,
          carname: item.name
        }
      })
      let personList = this.personList.map(item => {
        return {
          empid: item.id,
          empname: item.name
        }
      })
      let params = {
        name: this.name,
        principalId: this.principalId,
        contingencyPlan: this.contingencyPlan,
        startTime: this.startTime,
        endTime: this.endTime,
        planPicture: this.planPicture,
        detail: this.detail,
        desc: this.desc,
        carList: carList,
        personList: personList,
      }
      if (this.dataId) params.id = this.dataId
      if (this.site == 0) {
        params.lng = this.lng
        params.lat = this.lat
        params.address = this.address
      } else if (this.site == 1 || this.site == 2) {
        params.gridId = this.gridId
      }
      this.loading = true
      this.$store.dispatch('cearteOrEditEmergencyPlanning', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.$emit('update:resPlanId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.principal = ''
      this.principalId = ''
      this.contingencyPlan = ''
      this.startTime = ''
      this.endTime = ''
      this.planPicture = ''
      this.desc = ''
      this.site = 0
      this.gridName = ''
      this.gridId = ''
      this.carDeptId = ''
      this.carDeptName = ''
      this.personDeptId = ''
      this.personDeptName = ''
      this.carList = []
      this.carSelectList = []
      this.personList = []
      this.personSelectList = []
      this.lng = ''
      this.lat = ''
      this.address = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: 540px;
  .form-ul {
    .form-ul-li {
      p {
        // min-width: 110px;
      }
    }
  }
}
.list {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 7px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .list-item {
    width: 90px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px 6px 0;
    padding-left: 4px;
    border: 1px solid #e1e4e6;
    border-radius: 3px;
  }
}
.selectlist {
  flex: 1;
  height: 300px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    padding: 5px;
    background: #ffffff;
  }
  .cont {
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
    .cont-box {
      display: flex;
      flex-wrap: wrap;
      .cont-item {
        width: 57px;
        height: 50px;
        text-align: center;
        color: #ffffff;
        margin-right: 10px;
        margin-bottom: 8px;
        border-radius: 4px;
        cursor: pointer;
        p {
          padding-top: 3px;
          font-size: 16px;
        }
        span {
          font-size: 12px;
        }
      }
      .cont-item:nth-child(5n + 1) {
        margin-left: 10px;
      }
    }
  }
  .footer {
    line-height: 35px;
    display: flex;
    border-top: 1px solid #ddd;
    background: #ffffff;
    li {
      margin-left: 8px;
      span {
        display: inline-block;
        width: 14px;
        height: 12px;
        margin-right: 2px;
        vertical-align: -1px;
      }
    }
  }
  .green {
    background: #0eb36e;
  }
  .orange {
    background: #fe9400;
  }
  .red {
    background: #ff3b30;
  }
  .current {
    background: #009aff;
  }
}
</style>